@import 'styles/_breakpoints.scss';

.pageContent {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 180px;
}

.heading {
  font-family: copperplate, serif;
  font-weight: var(--regular-weight);
  font-size: 100px;
  color: var(--sky-white);
  padding: 0px;
  margin: 0;
  line-height: 56px;
}

.comment {
  padding-top: 91px;
  width: 365px;
  color: var(--sky-white);
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  text-align: center;
}

.button {
  width: 296px;
}

@media screen and (max-width: $sm) {
  .pageContent {
    margin-top: 150px;
  }

  .heading {
    font-size: 80px;
  }

  .comment {
    font-size: 14px;
    padding-left: 33px;
    padding-right: 33px;
  }
}
